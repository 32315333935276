
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

const LOADER_WIDTH_MIN = 20
const LOADER_WIDTH_MAX = 30

@Component({
  name: 'TableRowItem'
})
export default class TableRowItem extends Vue {
  @Prop({ type: Boolean }) readonly loading?: boolean
  @Prop() readonly width?: string

  loaderWidth = Math.floor(Math.random() * LOADER_WIDTH_MAX) + LOADER_WIDTH_MIN
}
