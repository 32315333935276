
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'TableSearchInput'
})
export default class TableSearchInput extends Vue {
  @Prop() readonly search!: string
  @Prop({ default: 'Search' }) readonly placeholder!: string

  focused = false
}
