
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export interface ButtonTab {
  id: string
  name: string
}

@Component({
  name: 'OverviewButtonTabs'
})
export default class extends Vue {
  @Prop() readonly tabs!: ButtonTab[]
  @Prop() readonly value!: string
}
