
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

type SortDirection = 'asc' | 'desc'

export interface ITableHeadItem {
  id: string
  sortable?: boolean
  title: string
  width?: string
}

@Component({
  name: 'TableHeadItem'
})
export default class TableHeadItem extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly width?: string
  @Prop({ default: true }) readonly sortable!: boolean
  @Prop({ default: undefined }) readonly sort?: SortDirection
}
